<template>
  <div class="v-service">
    <div class="service-about">
      <div class="service-header">Онколог</div>
      <div class="service-text">Врач-онколог занимается диагностикой, лечением опухолей, а также изучением преобразования доброкачественных опухолей в злокачественные. Часто первичные стадии заболевания протекают бессимптомно, поэтому качественная и максимально ранняя диагностика является важным фактором при лечении онкологических заболеваний.</div>
      <div class="service-text">При подозрении на появление опухоли или ее симптомов, настоятельно рекомендуем, как можно раньше обратиться к профессиональному онкологу, так как ранняя диагностика и своевременное, адекватное лечение существенно повышают вероятность выздоровления и снижают риски осложнений при этих непростых диагнозах.</div>
      <div class="service-text"><b>Когда рекомендована консультация онколога?</b></div>
      <div class="service-text" style="text-align: left;">
•	хронические воспалительные процессы;<br>
•	появление уплотнений, образований под кожей или на коже;<br>
•	наличие уплотнений и узлов в молочной железе;<br>
•	увеличение лимфатических узлов в подмышечной области или на шее;<br>
•	темные и большие родинки;<br>
•	отягощенная наследственность;<br>
•	папилломы, бородавки;<br>
•	быстрое, ничем не объяснимое похудение;<br>
•	кровь в естественных выделениях организма (моча, кал);<br>
•	увеличение грудных желез у мужчин;<br>
•	профессиональная вредность.<br>
      </div>
        <div class="service-header">Услуги врача онколога</div>
        <div class="price-table">
            <div class="price-line"></div>
            <div v-for="service in onkolog_services" :key="service">
                <div class="price-row">
                <div class="price-name">{{service.name}}</div>
                <div class="price-price">{{service.price}}</div>
                </div>
                <div class="price-line"></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'v-gastr',
    props: {
    },
    mounted() {
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = "Клиника здоровья онколог";
        document.body.appendChild(meta);
    },
    data() {
        return {
            onkolog_services: [
                {
                    name: 'Первичный прием',
                    price: '1 700 руб'
                },
                {
                    name: 'Повторный прием',
                    price: '1 500 руб'
                },
                {
                    name: 'Биопсия кожи, мягких тканей, лимфатических узлов',
                    price: '650 руб'
                },
                {
                    name: 'Забор анализа (или материала)',
                    price: '250 руб'
                },
                {
                    name: 'Цитологическое исследование пунктатов других органов и тканей (пунктат) 4 раб дня',
                    price: '500 руб'
                },
                {
                    name: 'Цитологическое исследование соскобов и отпечатков',
                    price: '500 руб'
                },
            ],
        }
    }
}
</script>

<style>
</style>
