<template>
  <div class="v-service">
    <div class="service-about">
        <div class="service-header">Терапевт</div>
        <div class="service-text">Терапевты являются первым звеном, способным помочь решить любые проблемы со здоровьем: назначить диагностику и лечение, направить к узким специалистам, помочь с госпитализацией. Если вы не знаете, к какому специалисту обратиться с вашей проблемой, то нужно обращаться именно к терапевту. И это — не лишняя трата времени и денег — одни и те же симптомы могут являться следствием очень разных заболеваний, и только специалист широкого профиля, такой как врач-терапевт, сможет разобраться в том, консультации и лечение какого специалиста необходимы в каждом конкретном случае.</div>
        <div class="service-text"><b>Когда необходима консультация терапевта?</b></div>
        <div class="service-text">Поводом для обращения к терапевту, помимо простудных и инфекционных заболеваний, служат следующие состояния организма:</div>
        <div class="service-text" style="text-align: left;">
            •	боли в груди, в области сердца, покалывания в конечностях;<br>
            •	боль в желудке, изжога, тошнота, рвота, понос, запор, нарушение стула;<br>
            •	частые головные боли, головокружения, мелькание «мушек» перед глазами;<br>
            •	резкие изменения артериального давления, общая слабость;<br>
            •	бессонница, чувство тревожности, депрессия;<br>
            •	сыпь на коже, шелушение, язвочки, зуд;<br>
            •	постоянное чувство жажды, сухости во рту, быстрой утомляемости;<br>
            •	боли в области поясницы, в суставах;<br>
            •	нарушение обмена веществ, резкий набор или потеря веса.<br>
        </div>
        <div class="service-text">При необходимости в дополнительной диагностике и/или специфическом лечении, после консультации, терапевт направит к нужному специалисту соответствующего профиля, сориентирует в последовательности действий и при необходимости проконтролирует процесс наблюдения у узкого специалиста.</div>
        <div class="service-header">Услуги врача терапевта</div>
        <div class="price-table">
            <div class="price-line"></div>
            <div v-for="service in terapevt_services" :key="service">
                <div class="price-row">
                <div class="price-name">{{service.name}}</div>
                <div class="price-price">{{service.price}}</div>
                </div>
                <div class="price-line"></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'v-gastr',
    props: {
    },
    mounted() {
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = "Клиника здоровья терапевт";
        document.body.appendChild(meta);
    },
    data() {
        return {
            terapevt_services: [
                {
                    name: 'Первичный прием',
                    price: '1 700 руб'
                },
                {
                    name: 'Повторный прием',
                    price: '1 400 руб'
                },
                {
                    name: 'Консультация на дому без ЭКГ',
                    price: '2 500 руб'
                },
                {
                    name: 'Консультация на дому + ЭКГ',
                    price: '3 500 руб'
                },
            ],
        }
    }
}
</script>

<style>
</style>
