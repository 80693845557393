<template>
<div class="v-service">
    <div class="service-about">
        <div class="service-header">Подготовка к УЗИ</div>
        <div v-for="rule in usi_rules" :key="rule">
            <div class="service-text">
                <b> {{ rule.name }} </b>
            </div>
            <div class="service-text">
                {{ rule.text }}
            </div>
        </div>

        <div v-for="services in usi_services" :key="services">
            <div class="service-header usi-header">
                {{ services.header }}
            </div>
            <div class="price-table">
                <div class="price-line"></div>
                <div v-for="service in services.services" :key="service">
                    <div class="price-row lor-analysis">
                        <div class="price-name">
                            {{ service.name }}
                        </div>
                        <div class="price-price">
                            {{ service.price }}
                        </div>
                    </div>
                    <div class="price-line"></div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'v-gastr',
    props: {
    },
    mounted() {
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = "Клиника здоровья УЗИ";
        document.body.appendChild(meta);
    },
    data() {
        return {
            usi_rules: [
                {
                    name: '1. УЗИ брюшной полости и почки',
                    text: 'За 3 дня до обследования исключить из рациона продукты, повышающие газообразование: черный хлеб, кисломолочные продукты, жирную пищу, сырые фрукты и овощи. Накануне самый поздний ужин до 19.00. в день обследования за 6 часов до обследования не кушать, за 4 часа не пить.'
                },
                {
                    name: '2. УЗИ брюшной полости, почки и мочевой пузырь',
                    text: 'За 3 дня до обследования исключить из рациона продукты, повышающие газообразование: черный хлеб, кисломолочные продукты, жирную пищу, сырые фрукты и овощи. Накануне самый поздний ужин до 19.00. в день обследования за 6 часов до обследования не кушать, за 4 часа – не пить и за 2 часа не мочиться. Если пациент принимает какие-то таблетки можно запить водой'
                },
                {
                    name: '3. УЗИ почек и мочевого пузыря',
                    text: 'За 1 час до обследования выпить 1 литр негазированной воды и не мочиться. При невозможности терпеть и сильном позыве, допустимо немного опорожнить мочевой пузырь для снятия напряжения и повторно выпить немного жидкости для достижения полного наполнения мочевого пузыря к моменту обследования.'
                },
                {
                    name: '4. УЗИ мочевого пузыря и предстательной железы (простаты) у мужчин абдоминально',
                    text: 'На полный мочевой пузырь. За 1 час до обследования выпить 1 литр негазированной воды и не мочиться.'
                },
                {
                    name: '5. ТРУЗИ (УЗИ предстательной железы ректально)',
                    text: 'За 2 часа до обследования опорожнить кишечник (сделать клизму). При отсутствии противопоказаний рекомендуется использование микроклизм.'
                },
                {
                    name: '6. УЗИ малого таза (гинекологическое)',
                    text: 'На полный мочевой пузырь. За 1 час до обследования выпить 1 литр негазированной воды и не мочиться. Обследование рекомендуется проводить с 5 по 7 день менструального цикла, либо по назначению врача. (Цикл считается с первого дня начала менструации).'
                },
                {
                    name: '7. УЗИ молочных желез',
                    text: 'Обследование рекомендуется проводить с 6 по 12 день менструального цикла. При отсутствии менструального цикла – в любой день. (Цикл считается с первого дня начала менструации).'
                },
                {
                    name: '8. УЗИ щитовидной железы',
                    text: 'Подготовки не требуется.'
                },
                {
                    name: '9. Контроль эндометрия',
                    text: 'Обследование рекомендуется проводить с 10 по 12 день менструального цикла. (Цикл считается с первого дня начала менструации).'
                },
                {
                    name: '10. УЗИ сердца',
                    text: 'Подготовки не требуется. При себе иметь «свежую» электрокардиограмму (ЭКГ)'
                },
                {
                    name: '11. УЗИ мошонки',
                    text: 'Подготовки не требуется.'
                },
            ],
            usi_services: [
                {
                    header: 'УЗИ органов брюшной полости',
                    services: [
                        {
                            name: 'Комплекс №1 (почки, надпочечники, печень, желчный пузырь, поджелудочная железа, селезенка, лимфоузлы, мочевой пузырь, объем ост.мочи)',
                            price: '1 800 руб'
                        },
                        {
                            name: 'Комплекс №2 (почки, надпочечники, печень, желчный пузырь, поджелудочная железа, селезенка, лимфоузлы)',
                            price: '1 700 руб'
                        },
                        {
                            name: 'Комплекс №3 (печень, желчный пузырь, поджелудочная железа, селезенка)',
                            price: '1 500 руб'
                        },
                        {
                            name: 'Комплекс №4 (печень, желчный пузырь, поджелудочная железа)',
                            price: '1 400 руб'
                        },
                    ]
                },
                {
                    header: 'УЗИ почек, мужских половых органов и мочевыделительной системы',
                    services: [
                        {
                            name: 'Почки, надпочечники',
                            price: '1 200 руб'
                        },
                        {
                            name: 'Почки, надпочечники, мочевой пузырь, объем ост. мочи',
                            price: '1 400 руб'
                        },
                        {
                            name: 'Почки, надпочечники, мочевой пузырь, предстательная железа, кол-во ост. мочи',
                            price: '1 900 руб'
                        },
                        {
                            name: 'Мочевой пузырь, объем ост. мочи',
                            price: '1 100 руб'
                        },
                        {
                            name: 'Мочевой пузырь',
                            price: '1 000 руб'
                        },
                        {
                            name: 'УЗИ мочевого пузыря, предстательной железы, объема ост. мочи',
                            price: '1 800 руб'
                        },
                        {
                            name: 'УЗИ предстательной железы 2 датчика (трансабдоминально + трансректально)',
                            price: '1 700 руб'
                        },
                        {
                            name: 'УЗИ предстательной железы 1 датчик (трансректально или трансабдаминально)',
                            price: '1 400 руб'
                        },
                        {
                            name: 'УЗИ мошонки',
                            price: '1 200 руб'
                        },
                    ]
                },
                {
                    header: 'УЗИ поверхностных органов',
                    services: [
                        {
                            name: 'УЗИ щитовидной железы ',
                            price: '1 200 руб'
                        },
                        {
                            name: 'УЗИ молочных желез',
                            price: '1 600 руб'
                        },
                        {
                            name: 'УЗИ мягких тканей (одна зона исследования)',
                            price: '1 300 руб'
                        },
                        {
                            name: 'УЗИ слюнных желез',
                            price: '1 300 руб'
                        },
                        {
                            name: 'УЗИ лимфатических узлов (шейные, подмышечные, паховые, 1 зона (шей, либо пара подмышечные или паховые с обеих сторон)',
                            price: '1 300 руб'
                        },
                    ]
                },
                {
                    header: 'УЗИ сердца и сосудов ',
                    services: [
                        {
                            name: 'УЗИ сердца',
                            price: '1 800 руб'
                        },
                        {
                            name: 'Триплексное обследование артерий одной нижней конечности',
                            price: '1 400 руб'
                        },
                        {
                            name: 'Триплексное обследование артерий двух нижних конечностей',
                            price: '2 300 руб'
                        },
                        {
                            name: 'Триплексное обследование вен одной нижней конечности',
                            price: '1 400 руб'
                        },
                        {
                            name: 'Триплексное обследование вен двух нижних конечностей',
                            price: '2 300 руб'
                        },
                        {
                            name: 'Триплексное обследование брахиоцефальных сосудов (внутренняя яремная вена, брахиоцефальные артерии)',
                            price: '1 600 руб'
                        },
                        {
                            name: 'Триплексное сканирование брюшной аорты и подвздошных артерий (без висцеральных ветвей)',
                            price: '1 600 руб'
                        },
                    ]
                },
                {
                    header: 'УЗИ в акушерстве и гинекологии',
                    services: [
                        {
                            name: 'УЗИ гинекологическое 2 датчиками (трасвагинально + трансабдоминально)',
                            price: '1 600 руб'
                        },
                        {
                            name: 'Состояние эндометрия',
                            price: '900 руб'
                        },
                        {
                            name: 'Мониторинг фолликулогенеза 1 датчик (трансвагинально)',
                            price: '1 000 руб'
                        },
                        {
                            name: 'УЗИ ранних сроков беременности до 10 нед',
                            price: '1 700 руб'
                        },
                        {
                            name: 'Допплерометрия плода',
                            price: '1 200 руб'
                        },
                        {
                            name: 'Цервикометрия',
                            price: '800 руб'
                        },
                        {
                            name: 'Цервикометрия + оценка рубца на матке',
                            price: '900 руб'
                        },
                        {
                            name: 'КТГ плода',
                            price: '800 руб'
                        },
                        {
                            name: 'Индекс амниотической жидкости (ИАЖ)',
                            price: '600 руб'
                        },
                        {
                            name: 'УЗИ плевральных полостей',
                            price: '800 руб'
                        },
                    ]
                },
            ]
        }
    }
}
</script>

<style>
@media screen and (max-width: 700px) {
    .usi-header {
        font-size: 24px;
    }
}
</style>
