<template>
  <div class="v-service">
    <div class="service-about">
        <div class="service-header">Кардиолог</div>
        <div class="service-text">Сердечно-сосудистые заболевания по всему миру считаются самой распространенной причиной ранней смертности. По статистике смерть от болезней сердца составляет 40-60% ежегодно от общего количества смертей. В России чрезвычайно распространены такие заболевания, как ишемическая болезнь сердца и артериальная гипертония.</div>
        <div class="service-text">Кардиолог — врач, специализирующийся на диагностике, лечении и профилактике сердечно-сосудистых заболеваний. Задача кардиолога — вовремя выявить и устранить факторы риска возникновения болезней сердца, предупредить развитие их осложнений, подобрать подходящее лечение</div>
        <div class="service-text"><b>В каких случаях необходима консультация кардиолога?</b></div>
        <div class="service-text" style="text-align: left;">
            •	при частых или периодических предобморочных состояниях и обмороках, возникающих без каких-либо на то причин;<br>
            •	боли в сердце, возникающей при физической нагрузке;<br>
            •	общей слабости организма, чрезмерной потливости, головокружениях, а также повышенной утомляемости;<br>
            •	повышении или снижении артериального давления;<br>
            •	возникновении чувства нехватки воздуха (одышки) во время физических нагрузок;<br>
            •	ощущении замирания, перебоев в работе сердца, при сильном сердцебиении в покое;<br>
            •	возникновении болей за грудиной жгучего, сжимающего или давящего характера, отдающих в левую руку, шею;<br>
            •	частых колющих болях в области сердца;<br>
            •	появлении отеков, особенно на нижних конечностях, не проходящих к утру.<br>
        </div>

        <div class="service-header">Услуги кардиолога</div>
        <div class="price-table">
            <div class="price-line"></div>
            <div v-for="service in cardiolog_services" :key="service">
                <div class="price-row">
                <div class="price-name">{{service.name}}</div>
                <div class="price-price">{{service.price}}</div>
                </div>
                <div class="price-line"></div>
            </div>
        </div>

        <div class="service-header">Услуги кардиолога Торошиной Т.В.</div>
        <div class="price-table">
            <div class="price-line"></div>
            <div v-for="service in toroshina_services" :key="service">
                <div class="price-row">
                <div class="price-name">{{service.name}}</div>
                <div class="price-price">{{service.price}}</div>
                </div>
                <div class="price-line"></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'v-gastr',
    props: {
    },
    mounted() {
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = "Клиника здоровья кардиолог";
        document.body.appendChild(meta);
    },
    data() {
        return {
            cardiolog_services: [
                {
                    name: 'Первичный прием (кандидат медицинских наук)',
                    price: '2 300 руб'
                },
                {
                    name: 'Повторный прием (кандидат медицинских наук)',
                    price: '2 100 руб'
                },
                {
                    name: 'Оформление МЭС',
                    price: '2 500 руб'
                },
                {
                    name: 'Консультация на дому без ЭКГ',
                    price: '3 200 руб'
                },
                {
                    name: 'Консультация на дому + ЭКГ',
                    price: '4 200 руб'
                },
                {
                    name: 'Электрокардиограмма (ЭКГ) с расшифровкой',
                    price: '700 руб'
                },
                {
                    name: 'Электрокардиограмма (ЭКГ) без расшифровки',
                    price: '500 руб'
                },
                {
                    name: 'Электрокардиограмма с нагрузкой (ЭКГ с нагрузкой) с расшифровкой',
                    price: '1300 руб'
                },
                {
                    name: 'Холтеровское мониторирование сердечного ритма (ХМ-ЭКГ) + Суточное мониторирование артериального давления (СМАД)',
                    price: '3 000 руб'
                },
                {
                    name: 'Холтеровское мониторирование сердечного ритма (ХМ-ЭКГ)',
                    price: '2 000 руб'
                },
                {
                    name: 'Суточное мониторирование артериального давления (СМАД)',
                    price: '1 800 руб'
                },
            ],
            toroshina_services: [
                {
                    name: 'Первичный прием',
                    price: '2 000 руб'
                },
                {
                    name: 'Повторный прием',
                    price: '1 800 руб'
                },
            ]
        }
    }
}
</script>

<style>
</style>
