<template>
  <div class="v-service">
    <div class="service-about">
        <div class="service-header">Онколог-маммолог</div>
        <div class="service-text">Женский организм подвержен частому изменению гормонального фона (менструация, беременность, лактация, менопауза). Особо активно это отражается на состоянии здоровья молочных желез и может спровоцировать возникновение опасных заболеваний. Именно поэтому женская грудь требует особого внимания и профилактического наблюдения у грамотного врача онколога-маммолога.</div>
        <div class="service-text">Неотъемлемой частью эффективного решения проблемы является качественная диагностика. В нашем центре можно пройти УЗИ молочных желез и маммографию;  при необходимости может быть проведен забор материала на исследование (биопсия).</div>
        <div class="service-text"><b>Женщинам стоит обратиться к маммологу при обнаружении следующих симптомов:</b></div>
        <div class="service-text" style="text-align: left;">
            •	молочная железа болезненная в покое или при дотрагивании, самостоятельном осмотре, боль усиливается перед менструациями;<br>
            •	молочные железы стали ассиметричными;<br>
            •	кожа молочной железы покрасневшая, отечная или бугристая (как лимонная корка);<br>
            •	изменилась форма соска или ареолы;<br>
            •	втянутый сосок;<br>
            •	появились выделения из соска (вне грудного вскармливания);<br>
            •	покраснение, отечность молочной железы, повышенная температура;<br>
            •	застой молока в период грудного вскармливания.<br>
        </div>
        <div class="service-header">Услуги онколога-маммолога</div>
        <div class="price-table">
            <div class="price-line"></div>
            <div v-for="service in mammolog_services" :key="service">
                <div class="price-row lor-analysis">
                <div class="price-name">{{service.name}}</div>
                <div class="price-price">{{service.price}}</div>
                </div>
                <div class="price-line"></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'v-mammolog',
    props: {
    },
    mounted() {
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = "Клиника здоровья маммолог";
        document.body.appendChild(meta);
    },
    data() {
        return {
            mammolog_services: [
                {
                    name: 'Первичный прием (осмотр, консультация)',
                    price: '2 400 руб'
                },
                {
                    name: 'Повторный приём',
                    price: '2 000 руб'
                },
                {
                    name: 'Пункция новообразований молочной железы прицельная пункционная без контроля ультразвукового исследования (без стоимости анализа)',
                    price: '3 000 руб'
                },
                {
                    name: 'Пункция новообразований молочной железы прицельная пункционная c контролем ультразвукового исследования (без стоимости анализа)',
                    price: '3 500 руб'
                },
                {
                    name: 'Иссечение новообразования молочной железы',
                    price: '14 100 руб'
                },
                {
                    name: 'Иссечение образования молочной железы',
                    price: '22 000 руб'
                },
                {
                    name: 'Иссечение образования молочной железы под наркозом',
                    price: '26 000 руб'
                },
                {
                    name: 'Перевязка',
                    price: '400 руб'
                },
                {
                    name: 'Контроль ультра звукового исследования',
                    price: '500 руб'
                },
                {
                    name: 'Цитологическое исследование 1 предметное стекло',
                    price: '500 руб'
                },
                {
                    name: 'Цитологическое исследование 2 предметных стекла',
                    price: '1 000 руб'
                },
                {
                    name: 'Цитологическое исследование 3 предметных стекла',
                    price: '1 500 руб'
                },
                {
                    name: 'Цитологическое исследование 4 предметных стекла',
                    price: '2 000 руб'
                },
            ],
        }
    }
}
</script>

<style>
</style>
